import * as React from "react";
import IndexLayout from "../layouts";
import { navigate } from "gatsby";
import { useEffect } from "react";

const WellbeingPlannerPage = () => {
  useEffect(() => {
    navigate("/education");
  }, []);

  return <IndexLayout></IndexLayout>;
};

export default WellbeingPlannerPage;
